import classnames from 'classnames';
import React from 'react';
import { graphql } from 'gatsby';

import BlockContent from 'molecules/BlockContent';
import Button from 'molecules/Button';
import Container from 'molecules/Container';
import FlyWheel from 'molecules/FlyWheel';
import Icon from 'molecules/Icon';
import Image from 'molecules/Image';
import Link from 'molecules/Link';
import Map from 'organisms/Map';
import SEO from 'molecules/SEO';
import WithBGImage from 'molecules/WithBGImage';

import './index.scss';

/**
 * About template.
 */
export const query = graphql`
  query ( $id: String ) {
    sanityPage( id: { eq: $id } ){
      id
      slug {
        current
      }
      title
      _rawPageTemplate(resolveReferences: { maxDepth: 10 })
      ...pageMetaFields
    }
  }
`;
const About = ( { data, path } ) => {

  const {
    title,
    meta,
    _rawPageTemplate: {
      About: {
        topSection,
        middleSection,
        quote,
        flywheel,
        team,
      }
    }
  } = data.sanityPage;


  return(

    <div className="About">

      <SEO
      title={ title }
      path={ path }
      { ...meta }
      />

      <section className="About__top">
        <Container>
          <div className="row">
            <div className="About__top-content col-md-7">
              <span className="d-block h-serif-i-sm">About Us</span>
              <h1 className="hding h-serif-md">
                { topSection.headline }
              </h1>
              <div className="p-lg">
                <BlockContent blocks={ topSection.content } />
              </div>
            </div>
            <div className="About__top-side col-md-5">
              <div className="map">
                <Map />
              </div>
              <div className="quotes p-md">
                <BlockContent blocks={ topSection.sideContent } />
              </div>
            </div>
          </div>
        </Container>
      </section>

      { !! middleSection &&
        <section className="About__mid">
          <Container>
            <h2 className="h-serif-lg">
              { middleSection.heading }
            </h2>
            <div className="About__mid-content p-lg">
              <BlockContent blocks={ middleSection.content } />
            </div>
          </Container>
        </section>
      }

      { !! quote &&
        <section className="About__quote">
          <Container size="md">
            <blockquote className="About__quote-content">
              { quote.content }
            </blockquote>
          </Container>
        </section>
      }

      { !! flywheel &&

        <section className="About__flywheel">
          <Container size="md">
            <h2 className="About__flywheel-hding h-lg">{ flywheel.heading }</h2>

            <div className="About__flywheel-wheel">
              <FlyWheel
              className="d-none d-md-block"
              size="md"
              />

              { !! flywheel.blurbs && !! flywheel.blurbs.length && flywheel.blurbs.map( blurb => (

                <div className="About__flywheel-blurb">
                  <div className="icon">
                    <Icon className={ `epsilon-${blurb.icon}` } />
                  </div>
                  <p className="p-sm">
                    { blurb.text }
                  </p>
                </div>

              ) ) }
            </div>

          </Container>
        </section>

      }

      { !! team &&
        <section className="About__team">
          <Container >

            <h2 className="About__team-hding h-serif-i-xl">
              Our Team
            </h2>

            <div className="row">
              { !! team.members.length && team.members.map( member => (

                <div
                key={ member._key }
                className="About__team-member col-md-6"
                >
                  <h3 className="h-serif-sm name">
                    { member.name }
                  </h3>
                  <span className="title d-block">
                    { member.title }
                  </span>
                  <div className="bio p-md">
                    <BlockContent blocks={ member.bio } />
                  </div>
                </div>

              ) ) }
            </div>

          </Container>
        </section>
      }

    </div>

  )

};

export default About;
